<template>
  <div>
    <h4 class="workplatform-title m-b-10">任务内容</h4>
    <Row>
      <i-col span="8">
        <label class="title m-r-5">名称</label>{{taskBean.name}}
      </i-col>
      <i-col span="8">
        <label class="title m-r-5">类型</label>{{taskBean.typeName}}
      </i-col>
      <i-col span="8">
        <label class="title m-r-5">状态</label>
        <Tag color="blue">{{taskBean.statusName}}</Tag>
      </i-col>
    </Row>
    <Row>
      <i-col span="8">
        <label class="title m-r-5">创建人</label>{{taskBean.createUserName}}
      </i-col>
      <i-col span="8">
        <label class="title m-r-5">创建时间</label>{{taskBean.createTime}}
      </i-col>
      <i-col span="8">
        <label class="title m-r-5">更新时间</label>{{taskBean.updateTime}}
      </i-col>
    </Row>
    <Row class="p-t-5">
      <i-col span="24"><label class="title m-r-5">审批备注</label>{{taskBean.remark}}</i-col>
    </Row>

    <Collapse
      class="m-t-20"
      simple
      v-model="defaultValue"
    >
      <Panel name="1">
        <b>任务明细</b>
        <div  slot="content">
          <Table
            stripe
            :data="itemData"
            size="small"
            :columns="tableColumns"
            :summary-method="handleSummary"
            show-summary
          ></Table>

          <div class="paging_style">
              <Page size="small"
              :total="itemTotalRow"
              :page-size="itemQuery.pageSize"
              show-total
              show-elevator
              @on-change="changePage"
              :current="itemQuery.pageNumber"
              ></Page>
          </div>
        </div>

      </Panel>
      <Panel
        name="2"
        v-if="task.processtype===17 && task.props.contractId"
      >
        <b>合同详情</b>
        <contractComponent
          slot="content"
          :theme="'black'"
          :id="task.props.contractId"
        ></contractComponent>
      </Panel>
    </Collapse>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >{{fileName}}</p>
      <img-light-box ref="imgLightBox"></img-light-box>
    </Modal>
  </div>
</template>

<script>
import contractComponent from '@/components/contract/contractDetail/Contract'

import { getApproveTask, getApproveTaskDetail, getTaskItemCharge } from '@/api/msp/task'
// import { getTaskItemPage } from '@/api/msp/taskitem'
import { toMoney } from '@/utils/wnumb_own'
import ImgLightBox from '@/components/common/ImgVideoLightBox'

export default {
  props: {
    id: [Number, String],
    task: {
      type: Object
    }
  },
  components: {
    contractComponent,
    ImgLightBox
  },
  data () {
    return {
      defaultValue: '1',
      taskBean: {},
      itemQuery: {
        pageNumber: 1,
        pageSize: 10,
        taskApproveId: 0
      },
      visiblePreview: false,
      fileName: '',
      sortIndex: 0,
      itemData: [],
      itemTotalRow: 0,
      tableColumns: [
        { title: '任务类型', key: 'typeName', align: 'center' },
        { title: '资源编号', key: 'resourceCode', align: 'center' },
        { title: '所属资产', key: 'assetName', align: 'center' },
        { title: '所属站台', key: 'domainName', align: 'center' },
        {
          title: '供应商',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.companyName)
          }
        },
        { title: '作业日期', key: 'actionDate', align: 'center' },
        {
          title: '备注',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            const remark = `${params.row.remark} ${params.row.extra}`
            return h('Tooltip', {
              props: {
                content: remark,
                transfer: true,
                'max-width': '200px'
              }
            }, remark.length > 12 ? remark.substr(0, 11) + '...' : remark)
          }
        },
        {
          title: '人工成本',
          key: 'cost1',
          align: 'center',
          render: (h, params) => {
            return h('span', this.computedCost(params.row, 1))
          }
        },
        {
          title: '材料成本',
          align: 'center',
          key: 'cost2',
          render: (h, params) => {
            return h('span', this.computedCost(params.row, 2))
          }
        },
        {
          title: '维修项',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.taskitemRepair.repairName)
          }
        },
        {
          title: '任务素材',
          minWidth: 100,
          align: 'center',
          key: 'fileId',
          render: (h, params) => {
            if (params.row.taskitemFiles && params.row.taskitemFiles.length > 0) {
              if (params.row.taskitemFiles.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.taskitemFiles
                      }
                      this.sortIndex = 0
                      this.fileName = params.row.stationName
                      this.$refs.imgLightBox.init(postData)
                      this.visiblePreview = true
                    }
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: params.row.taskitemFiles[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          const postData = {
                            startIndex: 0,
                            imgList: params.row.taskitemFiles
                          }
                          this.sortIndex = 0
                          this.fileName = params.row.domainName
                          this.$refs.imgLightBox.init(postData)
                          this.visiblePreview = true
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无素材')
            }
          }
        }
      ],
      summaryData: []
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const query = { taskApproveId: this.id, itemStatus: '4,5' }
      getApproveTask(query).then(res => {
        this.taskBean = res
        if (this.taskBean && this.taskBean.type !== 5) {
          // 非维修任务，删除维修项列
          this.tableColumns.splice(this.tableColumns.length - 2, 2)
        }
      })

      this.itemQuery.taskApproveId = this.id
      this.loadTaskDetailCharge()
      this.loadTaskDetail()
    },
    loadTaskDetailCharge () {
      getTaskItemCharge({ taskApproveId: this.id }).then(res => {
        this.summaryData = res
      })
    },
    loadTaskDetail () {
      getApproveTaskDetail(this.itemQuery).then(res => {
        this.itemData = res.list
        this.itemTotalRow = res.totalRow
      })
    },
    computedCost (params, type) {
      const costItem = params.taskitemCharges.find(x => x.costType === type)
      if (costItem) {
        return toMoney(costItem.confirmCost)
      } else {
        return toMoney(0)
      }
    },
    changePage (currentPage) {
      this.itemQuery.pageNumber = currentPage
      this.loadTaskDetail()
    },
    handleSummary ({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (key === 'cost1') {
          const cost1 = this.summaryData.find(x => x.costType === 1)

          sums[key] = {
            key,
            value: cost1 ? toMoney(cost1.cost) : '￥ 0.00'
          }
        } else if (key === 'cost2') {
          const cost2 = this.summaryData.find(x => x.costType === 2)

          sums[key] = {
            key,
            value: cost2 ? toMoney(cost2.cost) : '￥ 0.00'
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    }
  }
}
</script>
